// // import { IconQuote } from "@tabler/icons-react";
// import Img2 from "../images/testimonials/pfp1.jpg";
// import Img3 from "../images/testimonials/pfp2.jpg";

// import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

// function Testimonials() {
//   return (
//     <>
//       <section className="testimonials-section">
//         <div className="container">
//           <div className="testimonials-content">
//             <div className="testimonials-content__title">
//               <h4>Reviewed by People</h4>
//               <h2>Client's Testimonials</h2>
//               <p>
//                 Discover the positive impact we've made on the our clients by
//                 reading through their testimonials. Our clients have experienced
//                 our service and results, and they're eager to share their
//                 positive experiences with you.
//               </p>
//             </div>

//             <div className="all-testimonials">
//               <div className="all-testimonials__box">
//                 <span className="quotes-icon">
//                   <FormatQuoteIcon width={60} height={60} style={{fontSize:"70px"}}/>
//                 </span>
//                 <p>
//                   "I have rented a car from this company for more than one Year and half, the service is excellent and they are dealing in professional way with their customers. Moreover, the cars are clean and always well maintained.  Thank you Mr. Luqman for the gentle communication.
//  "
//                 </p>
//                 <div className="all-testimonials__box__name">
//                   <div className="all-testimonials__box__name__profile">
//                     <img src={"https://lh3.googleusercontent.com/a-/ALV-UjUEyiHiCgsDjw_mz6lUncrqI7GOiC9qBF6uIgHlyZhVjA=w36-h36-p-rp-mo-br100"} alt="user_img" />
//                     <span>
//                       <h4>Hammam Hilal</h4>
//                       {/* <p>Belgrade</p> */}
//                     </span>
//                   </div>
//                 </div>
//               </div>

//               <div className="all-testimonials__box box-2">
//                 <span className="quotes-icon">
//                   <FormatQuoteIcon width={60} height={60}  style={{fontSize:"70px"}}/>
//                 </span>
//                 <p>
//                   "Very affordable car rental company! Haven't gotten an issue since the time I rented a car here. They are very helpful when needed. Highly recommended! 👍🏼 …
// "
//                 </p>
//                 <div className="all-testimonials__box__name">
//                   <div className="all-testimonials__box__name__profile">
//                     <img src={"https://lh3.googleusercontent.com/a-/ALV-UjVO8-S0wYOfja_fWD0D9kIOMTF_fO_0WAuUBe8oW3QQ5_Q=w36-h36-p-rp-mo-br100"} alt="user_img" />
//                     <span>
//                       <h4>Graciela Anne Reynoso</h4>
//                       {/* <p>Novi Sad</p> */}
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// }

// export default Testimonials;







import React, { Component } from 'react';


import "./review.css"

import ban11 from '../assets/images/icon.svg';
class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
            
        





<br/>


<div class="container">
        <h2 style={{textAlign:"center"}}>Google Testimonials
        </h2>
        <p class="description">User feedback fuels our growth! At Falcon Rides Car Rental, we constantly listen, understand, and evolve to serve you better.

</p>
    
        <div class="reviewSection">

  

        
{/* 
<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
          <div style={{display:"flex"}}>
              <div>
                <p style={{height:"50px",width:"49px",fontSize:"24px",color:"white",fontWeight:"bold",background:"#454565",textAlign: "center",
    padding: "5px",
    borderRadius: "35px"}}>F</p>
              </div>
              <div style={{paddingLeft:"10px"}}>
                <span>Fatma Shahdad</span>
                <p className='review_para'>May 05, 2024</p>
              </div>
          </div>
          <div>
          <ul style={{color  :"rgb(25, 18, 43)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
              ★★★★★
          </ul>
        </div>
           
      </div>
          <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
  
    </div>
    <article>
        <p class="review">"For my first time renting a car in Dubai, I sure am glad to have stumbled upon this GEM. At first I was a little skeptical but as soon as I contacted them I knew they were legit. I was shocked to see such convenience, professionalism, and … More"</p>
        
    </article>
</div>
 */}








<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
 <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(25, 18, 43)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>F</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Fatma Shahdad</span>
                    <p class='review_para'>December 13, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(25, 18, 43)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"For my first time renting a car in Dubai, I sure am glad to have stumbled upon this GEM. At first I was a little skeptical but as soon as I contacted them I knew they were legit. I was shocked to see such convenience, professionalism, and … More"</p>
    </article>
</div>

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(125, 18, 43)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>M</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>MUHAMMAD Anas</span>
                    <p class='review_para'>November 20, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(125, 18, 43)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"I recently traveled to Dubai with my young family and booked the Falcon Rides car rental with chauffeur service for our week-long holiday. They also arranged our airport transfers, which made the experience even more seamless. … More"</p>
    </article>
</div>

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(15, 18, 143)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>A</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>abdulmunem</span>
                    <p class='review_para'>October 15, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(15, 18, 143)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"Falcon Rides Car Rental is hands down the best car rental service in Dubai. Their fleet of very well-maintained vehicles ensures a smooth and enjoyable driving experience, whether you choose a luxury car or an economical option. What sets … More"</p>
    </article>
</div>








        </div>
    </div>




          </div>
        )
    }
}

export default About;