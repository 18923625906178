// // import { IconQuote } from "@tabler/icons-react";
// import Img2 from "../images/testimonials/pfp1.jpg";
// import Img3 from "../images/testimonials/pfp2.jpg";

// import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

// function Testimonials() {
//   return (
//     <>
//       <section className="testimonials-section">
//         <div className="container">
//           <div className="testimonials-content">
//             <div className="testimonials-content__title">
//               <h4>Reviewed by People</h4>
//               <h2>Client's Testimonials</h2>
//               <p>
//                 Discover the positive impact we've made on the our clients by
//                 reading through their testimonials. Our clients have experienced
//                 our service and results, and they're eager to share their
//                 positive experiences with you.
//               </p>
//             </div>

//             <div className="all-testimonials">
//               <div className="all-testimonials__box">
//                 <span className="quotes-icon">
//                   <FormatQuoteIcon width={60} height={60} style={{fontSize:"70px"}}/>
//                 </span>
//                 <p>
//                   "I have rented a car from this company for more than one Year and half, the service is excellent and they are dealing in professional way with their customers. Moreover, the cars are clean and always well maintained.  Thank you Mr. Luqman for the gentle communication.
//  "
//                 </p>
//                 <div className="all-testimonials__box__name">
//                   <div className="all-testimonials__box__name__profile">
//                     <img src={"https://lh3.googleusercontent.com/a-/ALV-UjUEyiHiCgsDjw_mz6lUncrqI7GOiC9qBF6uIgHlyZhVjA=w36-h36-p-rp-mo-br100"} alt="user_img" />
//                     <span>
//                       <h4>Hammam Hilal</h4>
//                       {/* <p>Belgrade</p> */}
//                     </span>
//                   </div>
//                 </div>
//               </div>

//               <div className="all-testimonials__box box-2">
//                 <span className="quotes-icon">
//                   <FormatQuoteIcon width={60} height={60}  style={{fontSize:"70px"}}/>
//                 </span>
//                 <p>
//                   "Very affordable car rental company! Haven't gotten an issue since the time I rented a car here. They are very helpful when needed. Highly recommended! 👍🏼 …
// "
//                 </p>
//                 <div className="all-testimonials__box__name">
//                   <div className="all-testimonials__box__name__profile">
//                     <img src={"https://lh3.googleusercontent.com/a-/ALV-UjVO8-S0wYOfja_fWD0D9kIOMTF_fO_0WAuUBe8oW3QQ5_Q=w36-h36-p-rp-mo-br100"} alt="user_img" />
//                     <span>
//                       <h4>Graciela Anne Reynoso</h4>
//                       {/* <p>Novi Sad</p> */}
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// }

// export default Testimonials;







import React, { Component } from 'react';


import "./review.css"

import ban11 from '../assets/images/icon.svg';
class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
            
        





<br/>


<div class="container">
        <h2 style={{textAlign:"center"}}>Google Testimonials
        </h2>
        <p class="description">User feedback fuels our growth! At Falcon Rides Car Rental, we constantly listen, understand, and evolve to serve you better.

</p>
    
        <div class="reviewSection">

  

        

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
          <div style={{display:"flex"}}>
              <div>
                <p style={{height:"50px",width:"49px",fontSize:"24px",color:"white",fontWeight:"bold",background:"#454565",textAlign: "center",
    padding: "5px",
    borderRadius: "35px"}}>F</p>
              </div>
              <div style={{paddingLeft:"10px"}}>
                <span>Fatma Shahdad</span>
                <p className='review_para'>May 05, 2024</p>
              </div>
          </div>
          <div>
          <ul style={{color  :"rgb(25, 18, 43)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
              ★★★★★
          </ul>
        </div>
           
      </div>
          <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
  
    </div>
    <article>
        <p class="review">"For my first time renting a car in Dubai, I sure am glad to have stumbled upon this GEM. At first I was a little skeptical but as soon as I contacted them I knew they were legit. I was shocked to see such convenience, professionalism, and … More"</p>
        
    </article>
</div>







{/* 

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
 <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(25, 18, 43)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>F</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Fatma Shahdad</span>
                    <p class='review_para'>December 13, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(25, 18, 43)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"For my first time renting a car in Dubai, I sure am glad to have stumbled upon this GEM. At first I was a little skeptical but as soon as I contacted them I knew they were legit. I was shocked to see such convenience, professionalism, and … More"</p>
    </article>
</div> */}

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(125, 18, 43)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>M</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>MUHAMMAD Anas</span>
                    <p class='review_para'>November 20, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(125, 18, 43)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"I recently traveled to Dubai with my young family and booked the Falcon Rides car rental with chauffeur service for our week-long holiday. They also arranged our airport transfers, which made the experience even more seamless. … More"</p>
    </article>
</div>

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(15, 18, 143)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>A</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>abdulmunem</span>
                    <p class='review_para'>October 15, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(15, 18, 143)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"Falcon Rides Car Rental is hands down the best car rental service in Dubai. Their fleet of very well-maintained vehicles ensures a smooth and enjoyable driving experience, whether you choose a luxury car or an economical option. What sets … More"</p>
    </article>
</div>









<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(123, 114, 145)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>S</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Silvan D'Souza</span>
                    <p class='review_para'>October 10, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(123, 114, 145)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"Excellent service. The whole renting process was hassle-free. The Manager Luqman is quite soft-spoken and always available to answer all queries. The billing process was also very transparent. Would definitely recommend this company for the car rentals."</p>
    </article>
</div>

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(61, 110, 155)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>J</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Jc Rivas</span>
                    <p class='review_para'>September 25, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(61, 110, 155)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"As our first time to rent a car, we had a great experience with this company, especially Abdul and the owner. They were very responsive and helpful, and they were able to provide us the best experience in renting a car. Brand new MG ZS 2024. I would definitely recommend this company to others. Thank you."</p>
    </article>
</div>












<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(164, 168, 101)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>A</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Abdulmannan Farooq</span>
                    <p class='review_para'>August 30, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(164, 168, 101)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"I had a great experience with Falcon Rides Car Rental! The booking process was simple, the staff was friendly, and the car was clean and in great condition. Everything went smoothly from start to finish. I have visited twice and it still … More."</p>
    </article>
</div>











<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(151, 57, 86)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>M</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Moiz Raza</span>
                    <p class='review_para'>July 15, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(151, 57, 86)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"I had an excellent experience renting MG ZS with Falcon Rides. The car was well maintained and the whole process was very smooth. The staff was professional and highly responsive. Recommended to anyone looking for an affordable and reliable rental service."</p>
    </article>
</div>







<div class="reviewItem">
    <div class=" top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(85, 104, 85)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>A</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Amit Parashar</span>
                    <p class='review_para'>June 10, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(85, 104, 85)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"Rented a car twice with them so far, for a week long. Very straight forward process while renting and returning the car. Owner and staff is very warm and honest, I would seriously recommend them for their professionalism and work ethics."</p>
    </article>
</div>













<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(163, 105, 17)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>H</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Hassan Mujtaba</span>
                    <p class='review_para'>May 25, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(163, 105, 17)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"Amazing experience. Very good value for money. I would ask those looking for car rentals to come here as they have very nice cars with reasonable prices. Excellent."</p>
    </article>
</div>












<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(93, 85, 110)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>S</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Salman M Ashraf Niyaz</span>
                    <p class='review_para'>April 15, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(93, 85, 110)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"Falcon Rides Car Rental is the best car rent in UAE, Dubai. The car was in perfect condition without any problems. The service was very professional and amazing!!! I highly recommend using Falcon Rides Car Rental when renting a car. I have not complained nor have I gotten any headaches."</p>
    </article>
</div>

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(122, 67, 23)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>I</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Iman Farooq</span>
                    <p class='review_para'>March 10, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(122, 67, 23)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"Excellent car rental experience! The process was smooth, the staff was friendly, and the car was in perfect condition. Great rates and no hidden fees. Highly recommend!"</p>
    </article>
</div>










<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(35, 134, 138)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>O</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Omar</span>
                    <p class='review_para'>February 5, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(35, 134, 138)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"I recently had the absolute pleasure of renting a car in the UAE, and I must say, this experience was nothing short of phenomenal. From start to finish, this car rental service exceeded all expectations, proving why they are leagues ahead … More"</p>
    </article>
</div>







<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(56, 91, 189)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>S</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Shumais VK</span>
                    <p class='review_para'>January 20, 2024</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(56, 91, 189)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"Definitely these people are the best choice for those who are searching for a rent a car in Dubai. They really maintain a good relationship with customers and as a customer, I am so satisfied with their service. I always suggest my friends to visit here. They also have good luxury vehicles. Go and try yours!"</p>
    </article>
</div>











<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
 <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(78, 99, 29)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>M</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Mary Anne Buenaventura</span>
                    <p class='review_para'>December 1, 2023</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(78, 99, 29)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"Best Car Rental, cars well maintained you won’t have any headache. Price is reasonable also. I rented their Attrage for 55 AED daily for almost 4 months and it's amazing. I’ve searched other rental companies and yeah the price is almost BUT … More."</p>
    </article>
</div>







<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(94, 21, 66)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>M</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Mohab Alhusseini</span>
                    <p class='review_para'>November 15, 2023</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(94, 21, 66)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"It's amazing. No company rivals it. I came to the UAE and there was no car rental place that can come close to it. Best service and great accommodation."</p>
    </article>
</div>









<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(196, 214, 131)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>Z</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Zaheer Abbas</span>
                    <p class='review_para'>October 5, 2023</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(196, 214, 131)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"I have rented a car from Falcon Rides Car Rental. They have brand new cars, very professional service. Best in Al Karama."</p>
    </article>
</div>

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(127, 187, 197)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>K</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>Khawar Mir</span>
                    <p class='review_para'>September 1, 2023</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(127, 187, 197)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"My usual rental car business recommended this service. Luqman was patient with my queries. Overall excellent communication and delivery/pick up service. Will be using again in future."</p>
    </article>
</div>












<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            <div style={{display:"flex"}}>
                <div>
                    <p style={{height: "50px", width: "49px", fontSize: "24px", color: "white", fontWeight: "bold", background: "rgb(218, 59, 59)", textAlign: "center", padding: "5px", borderRadius: "35px"}}>A</p>
                </div>
                <div style={{paddingLeft:"10px"}}>
                    <span>AL SHAMS FARMS</span>
                    <p class='review_para'>August 15, 2023</p>
                </div>
            </div>
            <div>
                <ul style={{color  :"rgb(218, 59, 59)",fontSize:"24px",margin:"0px",color:"#f6bb06",lineHeight: "normal"}}>
                    ★★★★★
                </ul>
            </div>
        </div>
        <img src={ban11} alt="" style={{height:"55px",width:"40px",marginRight:"10px"}}/>
    </div>
    <article>
        <p class="review">"Amazing service, very professional dealing, no hidden charges. Highly recommend. Brand new cars available for rent."</p>
    </article>
</div>






        </div>
    </div>




          </div>
        )
    }
}

export default About;